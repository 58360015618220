<template>
  <div>
    <k-progress-circular :value="progress" :labeProgress="currentlabeProgress">
      <div v-if="[1, 4].includes(company.inputTypeId)">
        <h1>
          Sua empresa já está sendo aberta!
        </h1>
        <p class="s-subtitle-1 s-color-grey font-weight-bold">
          Agora que já validamos todas as suas informações, estamos efetuando a
          abertura da sua empresa junto aos organismos oficiais. Acompanhe a
          evolução do seu processo pela indicação abaixo e fique atento a seu
          email.
        </p>
      </div>
      <div v-if="[2, 5].includes(company.inputTypeId)">
        <h1>
          Estamos realizando a migração da sua contabilidade
        </h1>
        <p class="s-subtitle-1 s-color-grey font-weight-bold">
          Agora que já validamos todas as suas informações, estamos realizando a
          migração da sua contablidade. Acompanhe a evolução do seu processo
          pela indicação abaixo e fique atento a seu email.
        </p>
      </div>
      <div v-if="[3].includes(company.inputTypeId)">
        <h1>
          Estamos realizando a transformação da sua empresa
        </h1>
        <p class="s-subtitle-1 s-color-grey font-weight-bold">
          Agora que já validamos todas as suas informações, estamos realizando a
          transformação da sua empresa. Acompanhe a evolução do seu processo
          pela indicação abaixo e fique atento a seu email.
        </p>
      </div>
    </k-progress-circular>
    <blog></blog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { authMethods } from '@state/helpers'
const kProgressCircular = () =>
  import('@src/components/widget/ProgressCircular.vue')
import blog from '@components/registering/Blog.vue'
import longPulling from '@src/services/long-pulling'

export default {
  name: 'working',
  components: {
    blog,
    kProgressCircular,
    longPulling,
  },
  data() {
    return {
      dispatchProgress: 'backOffice/getBackOfficeProgress',
      dispatchUserUpdate: 'auth/fetchUser',
    }
  },
  computed: {
    ...mapGetters({
      progress: 'backOffice/progress',
      company: 'company/company',
    }),
    currentlabeProgress() {
      switch (this.company.inputTypeId) {
        case 1:
        case 4:
          return 'Abrindo sua empresa'
          break
        case 2:
        case 5:
          return 'Migrando sua empresa'
          break
        case 3:
          return 'Transformando sua empresa'
          break
      }
    },
  },
  mounted() {
    longPulling.start(this.dispatchUserUpdate)
    longPulling.start(this.dispatchProgress, 'working')
    this.sendToGA()
  },
  beforeDestroy() {
    longPulling.stop(this.dispatchProgress, 'working')
  },

  methods: {
    ...authMethods,
    sendToGA() {
      if (this.checkStepRegistration(7)) {
        this.$ga.event({
          eventCategory: this.company.isOppening ? 'Abertura' : 'Migração',
          eventAction: 'ativacao',
          eventLabel: 'execução',
          eventValue: 6,
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.no-box-shadow {
  box-shadow: none;
}
</style>
