<template>
  <v-row>
    <v-col
      cols="12"
      sm="5"
      md="5"
      lg="3"
      v-for="(item, index) in list"
      :key="index"
    >
      <v-card
        height="100%"
        :href="item.link"
        target="_blank"
        class="custom-card-blog elevation-2"
      >
        <v-row class="pa-2">
          <v-col cols="12" align-self="center" class="pb-0">
            <v-img class="elevation-5" :src="item.srcImage" />
          </v-col>
          <v-col cols="12">
            <p class="custom-normal-text font-weight-bold">
              {{ item.title }}
            </p>
            <span class="custom-normal-text">{{ item.description }}</span>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'company-blog',
  data() {
    return {}
  },
  props: {
    list: {
      require: false,
      type: Array,
      default: () => [
        {
          srcImage:
            'https://simplificador.com.br/wp-content/uploads/2018/03/pro-labore_distribuicao-2.jpg',
          title: 'Diferença entre Pró-labore e Distribuição de Lucros',
          author: 'Simplificador',
          date: '21 de março de 2018',
          link: 'https://simplificador.com.br/blog/pro-labore-distribuicao-lucros/',
          description:
            'Você sabe quais são os rendimentos tributáveis da sua empresa?',
        },
        {
          srcImage:
            'https://simplificador.com.br/wp-content/uploads/2017/07/simplestab-1.jpg',
          title:
            'Anexos do Simples Nacional destinados aos Prestadores de Serviços',
          author: 'Simplificador',
          date: '6 de julho de 2017',
          link: 'https://simplificador.com.br/blog/anexos-do-simples-nacional-destinados-aos-prestadores-de-servicos/',
          description: 'Sabia que são os anexos que determinam os impostos?',
        },
        {
          srcImage:
            'https://simplificador.com.br/wp-content/uploads/2017/09/Planejamento-Financeiro-1.jpg',
          title:
            '5 Dicas para o planejamento financeiro da sua micro ou pequena empresa',
          author: 'Simplificador',
          date: '6 de setembro de 2017',
          link: 'https://simplificador.com.br/blog/planejamento-financeiro/',
          description:
            'Acabou de abrir sua empresa e está precisando de ajuda?',
        },
        {
          srcImage:
            'https://simplificador.com.br/wp-content/uploads/2018/04/Conta-2.jpg',
          title:
            'Eu preciso abrir uma conta bancária em nome da minha empresa?',
          author: 'Simplificador',
          date: '18 de abril de 2018',
          link: 'https://simplificador.com.br/blog/conta-bancaria-para-empresa/',
          description: 'Esta também é sua dúvida? Confira!',
        },
      ],
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@design';
.custom-card-blog {
  &:hover p {
    color: $main-green !important;
  }
  border: 0.5px solid $main-grey80 !important;
}
</style>
